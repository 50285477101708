@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');

:root {
    --main-radius: 5px;
    --main-padding: 5px;
  }
  
  .credit-container {
    display: grid;
    grid-template-columns: 500px auto ;
    grid-template-rows: 0.7fr 0.7fr;
    grid-template-areas:
      "credit-content0 credit-content1"
      "credit-content2 credit-content2";
    grid-gap: 0.2rem;
    color: #004d40;
  }

  #credit-content0{
    grid-area: credit-content0
  }

  #credit-content1{
    grid-area: credit-content1
  }
  #credit-content2{
    grid-area: credit-content2
  }

  @media only screen and (max-width: 550px) {
    .credit-container {
      grid-template-columns: 250px auto;
      grid-template-rows: 0.2fr 0.2fr 0.4fr;
      grid-template-areas:
      "credit-content0 credit-content1"
      "credit-content2 credit-content2";
    }
  }


  /* #sidebar {
    grid-area: sidebar
  }

  #main {
    grid-area : main
  }

  .signUpContainer {
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 0.5rem;
    border-radius: 25px;
    width: 600px;

  }


  .signUpButton {
    border-radius: 20px;

  }

  .textfield {
    border-radius: 25px;
    background-color: white;
  }
  
  #content1 {

    border-radius: 25px;
    background-color: white;
  }
  
  #content2 {
    border-radius: 25px;
    background-color: white;
    
  }

  #content3{
    border-radius: 25px;
    background-color: white;
    
  }
  
  #content4 {
    border-radius: 25px;
    background-color: white;
    
  }


.AppBar {
    border-radius: 20px;
    background-color: white;
}

.subtitle {
    font-size: 18pt;
    color: darkgreen;
    font-family: "Montserrat";
    font-weight: 600;
}

.bodytext {
    font-size: 16pt;
    color: seagreen;
    font-family: "Montserrat";
}

.navButton {
    border-color: green;
    outline: 2px solid green;
}

.userForm{
    border-radius: 20px;
    border: 2px solid black;
    padding: 20px;
    max-width: 50%;
}

.jumboStyle {
    color: "black";
    height: "100vh";
    font-family: "Montserrat";
    font-size: "1vw";
    letter-spacing: "2px";
    display: "flex";
    align-items: "center";
    flex-direction: "column";
    justify-content: "center";
    text-align: "center";
    overflow: "hidden";
}

 */
