@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900);
body,
html {
  font-family: "Montserrat";
  background-image: url(/static/media/uucargo_background.732f39b1.png);
  background-position: center center;
  background-size: cover;
  background-repeat: inherit;
  height: 100%;
}

/* style sheet for "A4" printing */
@media print and (width: 21cm) and (height: 29.7cm) {
  body {
    margin: 3cm;
  }
}

/* sign-up grid*/
.sign-up-container {
  display: grid;
  grid-template-columns: 700px auto;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "sign-up-content0 sign-up-content0"
    "sign-up-content1 sign-up-content2";
  grid-gap: 0.5rem;
  -webkit-justify-content: center;
          justify-content: center;
}

#sign-up-content0 {
  grid-area: sign-up-content0;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: inline-block;
}

#sign-up-content1 {
  grid-area: sign-up-content1;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

#sign-up-content2 {
  grid-area: sign-up-content2;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
}

:root {
  --main-radius: 5px;
  --main-padding: 5px;
}

.container {
  display: grid;
  grid-template-columns: 0.4fr 0.5fr;
  grid-template-rows: 0.2fr 0.2fr;
  grid-template-areas:
    "content1 content2"
    "content3 content4"
    "content5 content6"
    "content7 content8"
    "content9 content10";
  grid-gap: 0.5rem;
  border-radius: 25px;
  background-color: white;
}

.creditContainer {
  display: grid;
  grid-template-columns: 0.7fr;
  grid-template-rows: 0.2fr;
  grid-template-areas:
    "content 1"
    "content 2"
    "content 3"
    "content 4"
    "content 5";
  grid-gap: 0.5rem;
  border-radius: 25px;
  -webkit-justify-content: center;
          justify-content: center;
}

.socialContainer {
  display: grid;
  grid-template-columns: 0.7fr;
  grid-template-rows: 0.2fr;
  grid-template-areas:
    "button1"
    "button2"
    "credentials1";
  grid-gap: 0.5rem;
  border-radius: 25px;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.searchHeader {
  background-color: white;
  width: 100%;
  min-width: 300px;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.MuiPaper-root {
  width: 40%;
  min-width: 200px;
  margin: 10px auto;
}

.MuiPaper-rounded {
  border-radius: 0%;
}

.search-setting-page {
  margin: 0 auto;
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.search-options {
  display: grid;
}

#shipmentInfo {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  text-align: center;
}

#button1 {
  color: green;
}

#button2 {
  color: green;
}

#crendentials1 {
  color: green;
}

#sidebar {
  grid-area: sidebar;
}

#main {
  grid-area: main;
}

.signUpButton {
  border-radius: 20px;
}

.textfield {
  border-radius: 25px;
  background-color: white;
}

#content1 {
  border-radius: 25px;
  background-color: white;
}

#content2 {
  border-radius: 25px;
  background-color: white;
}

#content3 {
  border-radius: 25px;
  background-color: white;
}

#content4 {
  border-radius: 25px;
  background-color: white;
}

@media only screen and (max-width: 550px) {
  .creditContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 0.4fr 0.4fr 0.4fr 0.4fr;
    grid-template-areas:
      "sidebar"
      "main";
  }
}

@media only screen and (max-width: 550px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 0.4fr 0.4fr 0.4fr 0.4fr;
    grid-template-areas:
      "content1"
      "content2"
      "content3"
      "content4";
  }
  .signUpContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    border-radius: 25px;
    width: 400px;
  }
}

.AppBar {
  background-color: white;
}

.subtitle {
  font-size: 18pt;
  color: darkgreen;
  font-family: "Montserrat";
  font-weight: 600;
}

.bodytext {
  font-size: 16pt;
  color: seagreen;
  font-family: "Montserrat";
}

.navButton {
  border-color: green;
  outline: 2px solid green;
}

.userForm {
  border-radius: 20px;
  border: 2px solid black;
  padding: 20px;
  max-width: 50%;
}

.jumboStyle {
  color: "black";
  height: "100vh";
  font-family: "Montserrat";
  font-size: "1vw";
  letter-spacing: "2px";
  display: "flex";
  -webkit-align-items: "center";
          align-items: "center";
  -webkit-flex-direction: "column";
          flex-direction: "column";
  -webkit-justify-content: "center";
          justify-content: "center";
  text-align: "center";
  overflow: "hidden";
}

.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png")
    6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* google */
.google-button {
  height: 40px;
  border-width: 0;
  background: white;
  color: #737373;
  border-radius: 5px;
  white-space: nowrap;
  box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
  transition-property: background-color, box-shadow;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
  padding: 0;
}

.google-button__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 8px 0 8px 8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
}

.google-button__icon--plus {
  width: 27px;
}

.google-button__text {
  display: inline-block;
  vertical-align: middle;
  padding: 0 24px;
  font-size: 14px;
  font-weight: bold;
  font-family: "Roboto", arial, sans-serif;
}

button ~ button {
  margin-left: 20px;
}

:root {
    --main-radius: 5px;
    --main-padding: 5px;
  }
  
  .credit-container {
    display: grid;
    grid-template-columns: 500px auto ;
    grid-template-rows: 0.7fr 0.7fr;
    grid-template-areas:
      "credit-content0 credit-content1"
      "credit-content2 credit-content2";
    grid-gap: 0.2rem;
    color: #004d40;
  }

  #credit-content0{
    grid-area: credit-content0
  }

  #credit-content1{
    grid-area: credit-content1
  }
  #credit-content2{
    grid-area: credit-content2
  }

  @media only screen and (max-width: 550px) {
    .credit-container {
      grid-template-columns: 250px auto;
      grid-template-rows: 0.2fr 0.2fr 0.4fr;
      grid-template-areas:
      "credit-content0 credit-content1"
      "credit-content2 credit-content2";
    }
  }


  /* #sidebar {
    grid-area: sidebar
  }

  #main {
    grid-area : main
  }

  .signUpContainer {
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 0.5rem;
    border-radius: 25px;
    width: 600px;

  }


  .signUpButton {
    border-radius: 20px;

  }

  .textfield {
    border-radius: 25px;
    background-color: white;
  }
  
  #content1 {

    border-radius: 25px;
    background-color: white;
  }
  
  #content2 {
    border-radius: 25px;
    background-color: white;
    
  }

  #content3{
    border-radius: 25px;
    background-color: white;
    
  }
  
  #content4 {
    border-radius: 25px;
    background-color: white;
    
  }


.AppBar {
    border-radius: 20px;
    background-color: white;
}

.subtitle {
    font-size: 18pt;
    color: darkgreen;
    font-family: "Montserrat";
    font-weight: 600;
}

.bodytext {
    font-size: 16pt;
    color: seagreen;
    font-family: "Montserrat";
}

.navButton {
    border-color: green;
    outline: 2px solid green;
}

.userForm{
    border-radius: 20px;
    border: 2px solid black;
    padding: 20px;
    max-width: 50%;
}

.jumboStyle {
    color: "black";
    height: "100vh";
    font-family: "Montserrat";
    font-size: "1vw";
    letter-spacing: "2px";
    display: "flex";
    align-items: "center";
    flex-direction: "column";
    justify-content: "center";
    text-align: "center";
    overflow: "hidden";
}

 */

/* shipping item grid */

.orange-title{
  color:orange;
  font-family: "Montserrat";
  font-weight: 600;
}

.shipping-item-container {
    display: grid;
    grid-template-columns: 0.4fr 0.4fr 0.4r ;
    grid-template-rows: 0.7fr 0.7fr 0.5fr 0.7fr;
    grid-template-areas: 
    "shipping-item0 shipping-item0 shipping-item0"
    "shipping-item1 shipping-item2 shipping-item3"
    "shipping-item4 shipping-item5 shipping-item6"
    "shipping-item4 shipping-item7 shipping-item7"
    "shipping-item4 shipping-item8 shipping-item8"
    "shipping-item9 shipping-item9 shipping-item10"
    "shipping-item11 shipping-item11 shipping-item12"
    "shipping-item13 shipping-item13 shipping-item13"
    "shipping-item14 shipping-item14 shipping-item14";
    grid-gap: 2rem;
    border-radius: 25px;
  
    -webkit-justify-content: center;
  
            justify-content: center;
    background-color: transparent;

  }

  .shipping-item-title{
    color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 130%;

  }

  .shipping-item-subtitle {
    color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 550;
}

  #shipping-item0 {
    border-radius: 25px;
    grid-area: shipping-item0;
    color:orange;
    font-family: "Montserrat";
    font-weight: 600;
  }

  #shipping-item1 {
    border-radius: 25px;
    grid-area: shipping-item1;
    
  }
  
  #shipping-item2 {
    border-radius: 25px;
    grid-area: shipping-item2; 
  }

  #shipping-item3{
    grid-area: shipping-item3;
    border-radius: 25px;
  }
  
  #shipping-item4 {
    border-radius: 25px;
    grid-area: shipping-item4;
  }

  #shipping-item5 {
    grid-area: shipping-item5;
    border-radius: 25px;
  }

  #shipping-item6 {
    grid-area: shipping-item6;
    border-radius: 25px;
  }

  #shipping-item7 {
    grid-area: shipping-item7;
    border-radius: 25px;
  }

  #shipping-item8 {
    grid-area: shipping-item8;
    border-radius: 25px;
    text-align: center;
  }

  #shipping-item9 {
    grid-area: shipping-item9;
    border-radius: 25px;
  }

  #shipping-item10 {
    grid-area: shipping-item10;
    border-radius: 25px;
    text-align: center;
  }

  #shipping-item11 {
    grid-area: shipping-item11;
    border-radius: 25px;
  }

  #shipping-item12 {
    grid-area: shipping-item12;
    border-radius: 25px;
    text-align: center;
  }

  #shipping-item13 {
    grid-area: shipping-item14;
    border-radius: 25px;
    text-align: center;
  }

  #shipping-item14 {
    grid-area: shipping-item14;
  }


  @media only screen and (max-width: 600px) {
    .shipping-item-container {
      grid-template-columns: 0.8fr;
      -webkit-justify-content: center;
              justify-content: center;
      text-align: center;
      grid-template-rows: 0.2fr auto ;
      grid-template-areas:
        "shipping-item0"
        "shipping-item1"
        "shipping-item2"
        "shipping-item3"
        "shipping-item4"
        "shipping-item5"
        "shipping-item6"
        "shipping-item7"
        "shipping-item8"
        "shipping-item9"
        "shipping-item10"
        "shipping-item11"
        "shipping-item12"
        "shipping-item13"
        "shipping-item14"
    }
  } 


   /* show items grid */

   .show-item-container {
    display: grid;
    grid-template-columns: 0.7fr;
    grid-template-rows: 0.7fr 0.7fr;
    grid-template-areas: 
    "show-item0 show-item0 show-item0"
    "show-item1 show-item1 show-item1";
    grid-gap: 2rem;
    border-radius: 25px;
    height:60vh;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: transparent;

  }

  #show-item0 {
    border-radius: 25px;
    grid-area: show-item0;
    font-family: "Montserrat";
    font-weight: 600;
  }

  #show-item1 {
    border-radius: 25px;
    grid-area: show-item1;
    text-align: center;
    
  }
  


  /* freight class grid 8*/
  .freight-class-container {
    display: grid;
    grid-template-columns: 0.2fr 0.6fr ;
    grid-template-rows: 0.2fr 0.2fr;
    grid-template-areas: 
    "freight-content1 freight-content2";
    -webkit-justify-content: center;
            justify-content: center;
    background-color: transparent;
  }

.freight-item-class{
  color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 250%;
}
.freight-item-subtitle{
  color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 190%;
}

.freight-item-body{
  color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 150%;
}

#freight-content0 {
  color:orange;
  font-family: "Montserrat";
  text-align: center;
  font-weight: 600;
}
#freight-content1 {
  grid-area: freight-content1;
  text-align: center;
  background-color:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1)

}
#freight-content2 {
  grid-area: freight-content2;
  background-color: white;
  text-align: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1)
}
#freight-content3 {
  grid-area: freight-content3
}
#freight-content4 {
  grid-area: freight-content4
}

.freight-button-center{
  text-align: center;
}

#freight-button-back {
  background-color: white;
}

#freight-button-forward {
 background-color: rgb(40, 116, 40);
}

.freightFlex {
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-flex-direction: row;
          flex-direction: row;
  color: rgb(40, 116, 40);
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 250%;
}


@media only screen and (max-width: 600px){
  .freight-class-container {
    grid-template-columns: 0.6fr;
    grid-template-rows: 0.2fr 0.2fr 0.4fr;
    grid-template-areas:
    "freight-content1 freight-content2"
  }
}

/*item-container*/

.item-container {
  display : grid;
  grid-template-columns: 1fr 0.2fr;
  grid-template-rows: 0.5fr 0.2fr;
  grid-template-areas: 
  "item-content1 item-content2";
  -webkit-justify-content: center;
          justify-content: center;
  background-color: transparent;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.item-content-title{
  color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 190%;
}
.item-content-subtitle{
  color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 150%;
}

#item-content1 {
  grid-area: item-content1;
  font-family: "Montserrat";
  background-color:white;
  text-align: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-weight: 600;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1)
}

#item-content2 {
  grid-area: item-content2;
  text-align: center;
  font-family: "Montserrat";
  background-color:white;
  -webkit-justify-content: center;
          justify-content: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1)

}


/*quote-summary container*/
.summary-container {
  display:grid;
  grid-template-columns: 500px auto ;
  grid-template-rows: 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr;
  grid-template-areas:
  "summary-content0 summary-content0 "
  "summary-content1 summary-content2"
  "summary-content3 summary-content4"
  "summary-content5 summary-content6"
  "summary-content7 summary-content8"
  "summary-content9 summary-content10";
  grid-gap: 0.5rem;
  border-radius: 25px;
  -webkit-justify-content: center;
          justify-content: center;
}

@media only screen and (max-width: 600px){
  .summary-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr 0.2fr 0.4fr;
    grid-template-areas:
    "summary-content0 summary-content0 "
  "summary-content1 summary-content2"
  "summary-content3 summary-content4"
  "summary-content5 summary-content6"
  "summary-content7 summary-content8"
  "summary-content9 summary-content10";
  }
}

#summary-content0 {
  border-radius: 25px;
  
}

#summary-content1 {
  border-radius: 25px;
  
}

#summary-content2 {
  border-radius: 25px;

  
}

#summary-content3{
  border-radius: 25px;
}

#summary-content4 {
  border-radius: 25px;
 
}

#summary-content5 {
  border-radius: 25px;
}

#summary-content6 {
  border-radius: 25px;
}

.summary-header {
  font-size: 16pt;
  color: rgb(141, 173, 155);
  font-family: "Montserrat";
}

.summary-body-text{
  font-size: 13pt;
  color: seagreen;
  font-family: "Montserrat";
}

/* review-and-confirm container*/

.review-container {
  display:grid;
  grid-template-columns: 500px auto ;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
  "review-content0 review-content1"
  "review-content2 review-content3"
  "review-content4 review-content5"
  "review-content6 review-content7"
  "review-content8 review-content9"
  "review-content10 review-content11"
  "review-content12 review-content13"
  "review-content14 review-content15"
  "review-content16 review-content17"
  "review-content18 review-content19"
  "review-content20 review-content21"
  "review-content22 review-content22"
  "review-content23 review-content24"
  "review-content25 review-content25"
  "review-content26 review-content26"
  "review-content27 review-content27"
  "review-content28 review-content28";
  grid-gap: 0.5rem;
  border-radius: 25px;
  -webkit-justify-content: center;
          justify-content: center;
}

#review-content0 {
  grid-area: review-content0;
 
}

#review-content1 {
  grid-area: review-content1;
}

#review-content2 {
  grid-area: review-content2;
}

#review-content3 {
  grid-area: review-content3;
}

#review-content3 {
  grid-area: review-content3;
}

#review-content4 {
  grid-area: review-content4;
}

#review-content5 {
  grid-area: review-content5;
}

#review-content6 {
  grid-area: review-content6;
}

#review-content7 {
  grid-area: review-content7;
}

#review-content8 {
  grid-area: review-content8;
}

#review-content9 {
  grid-area: review-content9;
}

#review-content10 {
  grid-area: review-content10;
}

#review-content11 {
  grid-area: review-content11;
}

#review-content12 {
  grid-area: review-content12;
}

#review-content13 {
  grid-area: review-content13;
}

#review-content14 {
  grid-area: review-content14;
}

#review-content15 {
  grid-area: review-content15;
}

#review-content16 {
  grid-area: review-content16;
}

#review-content17 {
  grid-area: review-content17;
}

#review-content18 {
  grid-area: review-content18;
}

#review-content19 {
  grid-area: review-content19;
}

#review-content20 {
  grid-area: review-content20;
}

#review-content21 {
  grid-area: review-content21;
}

#review-content22 {
  grid-area: review-content22;
  border-top: 1px solid black;
  
}

#review-content23 {
  grid-area: review-content23;
  font-weight: 600;
}

#review-content24 {
  grid-area: review-content24;
  font-weight: 600;
}

#review-content25 {
  grid-area: review-content25;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  grid-auto-flow: column;
}

#review-content26 {
  grid-area: review-content26;
}

#review-content27 {
  grid-area: review-content27;
}

#review-content28 {
  grid-area: review-content28;
}



@media only screen and (max-width: 600px){
  .review-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr 0.2fr 0.4fr;
    grid-template-areas:
    "review-content0 review-content1"
    "review-content2 review-content3"
    "review-content4 review-content5"
    "review-content6 review-content7"
    "review-content8 review-content9"
    "review-content10 review-content10"
    "review-content12 review-content13"
    "review-content14 review-content15"
    "review-content16 review-content17"
    "review-content18 review-content19"
    "review-content20 review-content21"
    "review-content22 review-content22"
    "review-content23 review-content24"
    "review-content25 review-content25"
    "review-content26 review-content26"
    "review-content27 review-content27"
    "review-content28 review-content28";
  }
}

.review-credit-container{
  display:grid;
  grid-template-columns: 0.3fr 0.6fr ;
  grid-template-rows: 0.7fr 0.7fr ;
  grid-template-areas:
  "review-credit-content0 review-credit-content1";
  grid-gap: 0.5rem;
  border-radius: 25px;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
}

.review-credit-content0{
  grid-area: review-credit-content0;
}

.review-credit-content1{
  grid-area: review-credit-content1;
}

body, html{
  font-family: "Montserrat";
  background-image:url(/static/media/uucargo_background.732f39b1.png)
}

a:hover {
    cursor:pointer;
   }

:root {
    --main-radius: 5px;
    --main-padding: 5px;
  }
  
  .quote-container {
    display: grid;
    grid-template-columns: 500px auto ;
    grid-template-rows: 0.6r 0.6fr 0.6fr 0.6fr 0.6fr;
    grid-template-areas: 
    "quote-content1 quote-content2"
    "quote-content3 quote-content2"
    "quote-content4 quote-content2"
    "quote-content5 quote-content2"
    "quote-content6 quote-content8"
    "quote-content7 quote-content8";
    grid-gap: 0.5rem;
    border-radius: 25px;
    -webkit-justify-content: center;
            justify-content: center;
    
  }

  @media only screen and (max-width: 550px) {
    .quote-container {
      grid-template-columns: 1fr ;
      grid-template-rows: 0.4fr auto ;
      grid-template-areas:
        "quote-content1"
        "quote-content2"
        "quote-content3"
        "quote-content4"
        "quote-content5"
        "quote-content6"
        "quote-content7"
        "quote-content8"
    }
  }

  #blank {
      grid-area: blank;
  }

  #side {
      grid-area: side;
  }
  
  #quote-content1 {
    border-radius: 25px;
    grid-area: quote-content1;
    color:orange;
    
  }
  
  #quote-content2 {
    border-radius: 25px;
    grid-area: quote-content2;
    text-align: center;
    
  }

  #quote-content3{
    grid-area: quote-content3;
    border-radius: 25px;
    
  }
  
  #quote-content4 {
    border-radius: 25px;
    grid-area: quote-content4;
  }

  #quote-content5 {
    grid-area: quote-content5;
    border-radius: 25px;
  }

  #quote-contentt6 {
    grid-area: quote-content6;
    border-radius: 25px;
  }

  #quote-content7 {
    grid-area: quote-content7;
    border-radius: 25px;
  }

  #quote-content8 {
    grid-area: quote-content8;
    border-radius: 25px;
    text-align: center;
  }

  #iconbuttonarrow {
      background-color:rgb(30, 100, 30);
  }

  #button1{
    color:green;
  }

  #button2{
    color: green;
  }

  #crendentials1{
    color:green;
  }

  #sidebar {
    grid-area: sidebar
  }

  #main {
    grid-area : main
  }

  .signUpContainer {
    display: grid;
    grid-template-columns: 1fr ;
    grid-gap: 0.5rem;
    border-radius: 25px;
    width: 400px;
  }

  .signUpButton {
    border-radius: 20px;

  }

  .textfield {
    border-radius: 25px;
    background-color: white;
  }

.AppBar {
  margin-top: 0px;
  background-color: white;
}

.title {
    font-size: 18pt;
    color: orange;
    font-family: "Montserrat";
    font-weight: 600;
}

.subtitle {
    font-size: 18pt;
    color: darkgreen;
    font-family: "Montserrat";
    font-weight: 600;
}


.bodytext {
    font-size: 16pt;
    color: seagreen;
    font-family: "Montserrat";
}

.navButton {
    border-color: green;
    outline: 2px solid green;
}

.userForm{
    border-radius: 20px;
    border: 2px solid black;
    padding: 20px;
    max-width: 50%;
}

.jumboStyle {
    color: "black";
    height: "100vh";
    font-family: "Montserrat";
    font-size: "1vw";
    letter-spacing: "2px";
    display: "flex";
    -webkit-align-items: "center";
            align-items: "center";
    -webkit-flex-direction: "column";
            flex-direction: "column";
    -webkit-justify-content: "center";
            justify-content: "center";
    text-align: "center";
    overflow: "hidden";
}


button ~ button {
  margin-left: 20px;
}



.order-container {
  display: grid;
  grid-template-columns: 700px auto;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "order-content0 order-content0"
    "order-content2 order-content3"
    "order-content4 order-content5"
    "order-content6 order-content7"
    "order-content8 order-content9"
    "order-content10 order-content11"
    "order-content12 order-content13"
    "order-content14 order-content15"
    "order-content16 order-content17"
    "order-content18 order-content19"
    "order-content20 order-content21"
    "order-content22 order-content23"
    "order-content24 order-content24"
    "order-content25 order-content25"
    "order-content-update order-content-update"
    "order-content1 order-content1"
    "order-content26 order-content26"
    "order-content27 order-content27"
    "order-content28 order-content28";
  /* "order-content23 order-content24"
    "order-content25 order-content25"
    "order-content26 order-content26"
    "order-content27 order-content27"
    "order-content28 order-content28"; */
  grid-gap: 0.5rem;
  border-radius: 25px;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: rgba(255, 255, 255, 0.788);
}

.order-list-text {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(61, 21, 21);
  font-family: Montserrat;
}

.shipment-text {
  font-size: 12pt;
  color: rgb(61, 21, 21);
  font-family: Montserrat;
}

.shipping-status-text {
  font-size: 10pt;
  color: rgb(194, 146, 14);
  font-family: Montserrat;
}

#order-content-update {
  grid-area: order-content-update;
}

#order-content0 {
  grid-area: order-content0;
}

#order-content1 {
  grid-area: order-content1;
  text-align: center;
}

#order-content2 {
  grid-area: order-content2;
  background-color: rgba(152, 158, 158, 0.274);
}

#order-content3 {
  grid-area: order-content3;
  background-color: rgba(152, 158, 158, 0.288);
}

#order-content3 {
  grid-area: order-content3;
}

#order-content4 {
  grid-area: order-content4;
}

#order-content5 {
  grid-area: order-content5;
}

#order-content6 {
  grid-area: order-content6;
}

#order-content7 {
  grid-area: order-content7;
}

#order-content8 {
  grid-area: order-content8;
  border-bottom: 1px solid black;
}

#order-content9 {
  grid-area: order-content9;
  border-bottom: 1px solid black;
}

#order-content10 {
  grid-area: order-content10;
}

#order-content11 {
  grid-area: order-content11;
}

#order-content12 {
  grid-area: order-content12;
  border-bottom: 1px solid black;
}

#order-content13 {
  grid-area: order-content13;
  border-bottom: 1px solid black;
}

#order-content14 {
  grid-area: order-content14;
}

#order-content15 {
  grid-area: order-content15;
}

#order-content16 {
  grid-area: order-content16;
  border-bottom: 1px solid black;
}

#order-content17 {
  grid-area: order-content17;
  border-bottom: 1px solid black;
}

#order-content18 {
  grid-area: order-content18;
}

#order-content19 {
  grid-area: order-content19;
}

#order-content20 {
  grid-area: order-content20;
  border-bottom: 1px solid black;
}

#order-content21 {
  grid-area: order-content21;
  border-bottom: 1px solid black;
}

#order-content22 {
  grid-area: order-content22;
}

#order-content23 {
  grid-area: order-content23;
  font-weight: 600;
}

#order-content24 {
  grid-area: order-content24;
  font-weight: 600;
  font-size: 15pt;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  grid-auto-flow: column;
}

#order-content25 {
  grid-area: order-content25;
  font-weight: 600;
  font-size: 15pt;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  grid-auto-flow: column;
}

#order-content26 {
  grid-area: order-content26;
}

#order-content27 {
  grid-area: order-content27;
}

#order-content28 {
  grid-area: order-content28;
}

@media only screen and (max-width: 600px) {
  .order-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr auto;
    grid-template-areas:
      "order-content0 order-content0"
      "order-content2 order-content3"
      "order-content4 order-content5"
      "order-content6 order-content7"
      "order-content8 order-content9"
      "order-content10 order-content11"
      "order-content12 order-content13"
      "order-content14 order-content15"
      "order-content16 order-content17"
      "order-content18 order-content19"
      "order-content20 order-content21"
      "order-content22 order-content23"
      "order-content24 order-content24"
      "order-content25 order-content25"
      "order-content-update order-content-update"
      "order-content1 order-content1"
      "order-content26 order-content26"
      "order-content27 order-content27"
      "order-content28 order-content28";
  }
}

/*Quote Summary Container Order Details*/
.quote-order-container {
  display: grid;
  grid-template-columns: 500px auto;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "quote-order-content0 quote-order-content0"
    "quote-order-content1 quote-order-content2"
    "quote-order-content3 quote-order-content4"
    "quote-order-content5 quote-order-content6"
    "quote-order-content7 quote-order-content8"
    "quote-order-content10 quote-order-content11"
    "quote-order-content12 quote-order-content13"
    "quote-order-content14 quote-order-content15";
  /* "quote-order-content23 quote-order-content24"
    "quote-order-content25 quote-order-content25"
    "quote-order-content26 quote-order-content26"
    "quote-order-content27 quote-order-content27"
    "quote-order-content28 quote-order-content28"; */
  grid-gap: 0.5rem;
  border-radius: 25px;
}

#quote-order-content0 {
  grid-area: quote-order-content0;
  text-align: center;
}

#quote-order-content1 {
  grid-area: quote-order-content1;
}
#quote-order-content2 {
  grid-area: quote-order-content2;
}

#quote-order-content3 {
  grid-area: quote-order-content3;
}

#quote-order-content3 {
  grid-area: quote-order-content3;
}

#quote-order-content4 {
  grid-area: quote-order-content4;
}

#quote-order-content5 {
  grid-area: quote-order-content5;
}

#quote-order-content6 {
  grid-area: quote-order-content6;
}

#quote-order-content7 {
  grid-area: quote-order-content7;
}

#quote-order-content8 {
  grid-area: quote-order-content8;
  quote-order-bottom: 1px solid black;
}

#quote-order-content9 {
  grid-area: quote-order-content9;
  quote-order-bottom: 1px solid black;
}

#quote-order-content10 {
  grid-area: quote-order-content10;
}

#quote-order-content11 {
  grid-area: quote-order-content11;
}

#quote-order-content12 {
  grid-area: quote-order-content12;
  quote-order-bottom: 1px solid black;
}

#quote-order-content13 {
  grid-area: quote-order-content13;
  quote-order-bottom: 1px solid black;
}

@media only screen and (max-width: 600px) {
  .quote-order-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr auto;
    grid-template-areas:
      "quote-order-content0 quote-order-content0"
      "quote-order-content1 quote-order-content2"
      "quote-order-content3 quote-order-content4"
      "quote-order-content5 quote-order-content6"
      "quote-order-content7 quote-order-content8"
      "quote-order-content10 quote-order-content11"
      "quote-order-content12 quote-order-content13"
      "quote-order-content14 quote-order-content15";
  }
}

.package-tab-container {
  display: grid;
  grid-template-columns: 500px auto;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "package-tab-content0 package-tab-content0"
    "package-tab-content1 package-tab-content1"
    "package-tab-content2 package-tab-content2"
    "package-tab-content3 package-tab-content3"
    "package-tab-content4 package-tab-content4"
    "package-tab-content5 package-tab-content5"
    "package-tab-content6 package-tab-content7"
    "package-tab-content8 package-tab-content9"
    "package-tab-content10 package-tab-content11"
    "package-tab-content12 package-tab-content13"
    "package-tab-content14 package-tab-content15"
    "package-tab-content16 package-tab-content17"
    "package-tab-content18 package-tab-content19"
    "package-tab-content20 package-tab-content21"
    "package-tab-content22 package-tab-content23"
    "package-tab-content24 package-tab-content25"
    "package-tab-content26 package-tab-content26";
  /* "package-tab-content23 package-tab-content24"
    "package-tab-content25 package-tab-content25"
    "package-tab-content26 package-tab-content26"
    "package-tab-content27 package-tab-content27"
    "package-tab-content28 package-tab-content28"; */
  grid-gap: 0.5rem;
  border-radius: 25px;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: rgba(255, 255, 255, 0.788);
}

#package-tab-content0 {
  grid-area: package-tab-content0;
  text-align: center;
}

#package-tab-content1 {
  grid-area: package-tab-content1;
  text-align: center;
}

#package-tab-content2 {
  grid-area: package-tab-content2;
  background-color: rgba(152, 158, 158, 0.274);
  font-size: 16pt;
  font-weight: 500;
  border-radius: 10px;
}

#package-tab-content3 {
  grid-area: package-tab-content3;
}

#package-tab-content4 {
  grid-area: package-tab-content4;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

#package-tab-content5 {
  grid-area: package-tab-content5;
}

#package-tab-content6 {
  grid-area: package-tab-content6;
}

#package-tab-content7 {
  grid-area: package-tab-content7;
}

#package-tab-content8 {
  grid-area: package-tab-content8;
}

#package-tab-content9 {
  grid-area: package-tab-content9;
}

#package-tab-content10 {
  grid-area: package-tab-content10;
}

#package-tab-content11 {
  grid-area: package-tab-content11;
}

#package-tab-content12 {
  grid-area: package-tab-content12;
  border-bottom: 1px solid black;
}

#package-tab-content13 {
  grid-area: package-tab-content13;
  border-bottom: 1px solid black;
}

#package-tab-content14 {
  grid-area: package-tab-content14;
}

#package-tab-content15 {
  grid-area: package-tab-content15;
}

#package-tab-content16 {
  grid-area: package-tab-content16;
  border-bottom: 1px solid black;
}

#package-tab-content17 {
  grid-area: package-tab-content17;
  border-bottom: 1px solid black;
}

#package-tab-content18 {
  grid-area: package-tab-content18;
}

#package-tab-content19 {
  grid-area: package-tab-content19;
}

#package-tab-content20 {
  grid-area: package-tab-content20;
  border-bottom: 1px solid black;
}

#package-tab-content21 {
  grid-area: package-tab-content21;
  border-bottom: 1px solid black;
}

#package-tab-content22 {
  grid-area: package-tab-content22;
}

#package-tab-content23 {
  grid-area: package-tab-content23;
  font-weight: 600;
}

#package-tab-content24 {
  grid-area: package-tab-content24;
  font-weight: 600;
  font-size: 15pt;
}

#package-tab-content25 {
  grid-area: package-tab-content25;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  grid-auto-flow: column;
}

#package-tab-content26 {
  grid-area: package-tab-content26;
}

#package-tab-content27 {
  grid-area: package-tab-content27;
}

#package-tab-content28 {
  grid-area: package-tab-content28;
}

@media only screen and (max-width: 600px) {
  #package-tab-content4 {
    grid-area: package-tab-content4;
    display: inline-block;
  }
  .package-tab-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr auto;
    grid-template-areas:
      "package-tab-content0 package-tab-content0"
      "package-tab-content1 package-tab-content1"
      "package-tab-content2 package-tab-content2"
      "package-tab-content3 package-tab-content3"
      "package-tab-content4 package-tab-content4"
      "package-tab-content5 package-tab-content5"
      "package-tab-content6 package-tab-content7"
      "package-tab-content8 package-tab-content9"
      "package-tab-content10 package-tab-content11"
      "package-tab-content12 package-tab-content13"
      "package-tab-content14 package-tab-content15"
      "package-tab-content16 package-tab-content17"
      "package-tab-content18 package-tab-content19"
      "package-tab-content20 package-tab-content21"
      "package-tab-content22 package-tab-content23"
      "package-tab-content24 package-tab-content25"
      "package-tab-content26 package-tab-content26";
  }
}

/*Cancel*/

.cancel-container {
  display: grid;
  grid-template-columns: 500px auto;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "cancel-content0 cancel-content0"
    "cancel-content1 cancel-content2"
    "cancel-content3 cancel-content4"
    "cancel-content5 cancel-content6"
    "cancel-content7 cancel-content8"
    "cancel-content9 cancel-content9 "
    "cancel-content10 cancel-content10"
    "cancel-content11 cancel-content11";
  /* "cancel-content12 cancel-content13"
    "cancel-content14 cancel-content15"
    "cancel-content16 cancel-content17"
    "cancel-content18 cancel-content19"
    "cancel-content20 cancel-content21"
    "cancel-content22 cancel-content23"
    "cancel-content24 cancel-content25"
    "cancel-content26 cancel-content26";
    "cancel-content23 cancel-content24"
    "cancel-content25 cancel-content25"
    "cancel-content26 cancel-content26"
    "cancel-content27 cancel-content27"
    "cancel-content28 cancel-content28"; */
  grid-gap: 0.5rem;
  border-radius: 25px;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: rgba(255, 255, 255, 0.788);
}

#cancel-content0 {
  grid-area: cancel-content0;
  text-align: center;
}

#cancel-content1 {
  grid-area: cancel-content1;
}

#cancel-content2 {
  grid-area: cancel-content2;
  font-size: 16pt;
  font-weight: 500;
  border-radius: 10px;
}

#cancel-content3 {
  grid-area: cancel-content3;
}

#cancel-content3 {
  grid-area: cancel-content3;
  border-bottom: 1px solid black;
}

#cancel-content4 {
  grid-area: cancel-content4;
}

#cancel-content5 {
  grid-area: cancel-content5;
}

#cancel-content6 {
  grid-area: cancel-content6;
}

#cancel-content7 {
  grid-area: cancel-content7;
}

#cancel-content8 {
  grid-area: cancel-content8;
}

#cancel-content9 {
  grid-area: cancel-content9;
  text-align: center;
}

#cancel-content10 {
  grid-area: cancel-content10;
}

#cancel-content11 {
  grid-area: cancel-content11;
  text-align: center;
}

#cancel-content12 {
  grid-area: cancel-content12;
  border-bottom: 1px solid black;
}

#cancel-content13 {
  grid-area: cancel-content13;
  border-bottom: 1px solid black;
}

#cancel-content14 {
  grid-area: cancel-content14;
}

#cancel-content15 {
  grid-area: cancel-content15;
}

#cancel-content16 {
  grid-area: cancel-content16;
  border-bottom: 1px solid black;
}

#cancel-content17 {
  grid-area: cancel-content17;
  border-bottom: 1px solid black;
}

#cancel-content18 {
  grid-area: cancel-content18;
}

#cancel-content19 {
  grid-area: cancel-content19;
}

#cancel-content20 {
  grid-area: cancel-content20;
  border-bottom: 1px solid black;
}

#cancel-content21 {
  grid-area: cancel-content21;
  border-bottom: 1px solid black;
}

#cancel-content22 {
  grid-area: cancel-content22;
}

#cancel-content23 {
  grid-area: cancel-content23;
  font-weight: 600;
}

#cancel-content24 {
  grid-area: cancel-content24;
  font-weight: 600;
  font-size: 15pt;
}

#cancel-content25 {
  grid-area: cancel-content25;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  grid-auto-flow: column;
}

#cancel-content26 {
  grid-area: cancel-content26;
}

#cancel-content27 {
  grid-area: cancel-content27;
}

#cancel-content28 {
  grid-area: cancel-content28;
}

@media only screen and (max-width: 600px) {
  .cancel-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr auto;
    grid-template-areas:
      "cancel-content0 cancel-content0"
      "cancel-content1 cancel-content2"
      "cancel-content3 cancel-content4"
      "cancel-content5 cancel-content6"
      "cancel-content7 cancel-content8"
      "cancel-content9 cancel-content9 "
      "cancel-content10 cancel-content10"
      "cancel-content11 cancel-content11";
  }
}

/*OrderList Container*/
.order-list-container {
  display: grid;
  grid-template-columns: 500px auto;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
    "order-list-content0 order-list-content0"
    "order-list-content1 order-list-content1"
    "order-list-content2 order-list-content2"
    "order-list-content3 order-list-content3"
    "order-list-content4 order-list-content5"
    "order-list-content6 order-list-content7"
    "order-list-content8 order-list-content9"
    "order-list-content10 order-list-content11";
  /* "order-list-content23 order-list-content24"
    "order-list-content25 order-list-content25"
    "order-list-content26 order-list-content26"
    "order-list-content27 order-list-content27"
    "order-list-content28 order-list-content28"; */
  grid-gap: 1rem;
  border-radius: 25px;
  -webkit-justify-content: center;
          justify-content: center;
}

.order-list-list-text {
  font-size: 16pt;
  font-weight: 500;
  color: rgb(61, 21, 21);
  font-family: Montserrat;
}

#order-list-content-update {
  grid-area: order-list-content-update;
}

#order-list-content0 {
  grid-area: order-list-content0;
  text-align: center;
}

#order-list-content1 {
  grid-area: order-list-content1;
  text-align: center;
}

#order-list-content2 {
  grid-area: order-list-content2;
  text-align: center;
}

#order-list-content3 {
  grid-area: order-list-content3;
  background-color: rgba(225, 235, 235, 0.938);
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  overflow: auto;
}

#order-list-content3 {
  grid-area: order-list-content3;
}

#order-list-content4 {
  grid-area: order-list-content4;
}

#order-list-content5 {
  grid-area: order-list-content5;
}

#order-list-content6 {
  grid-area: order-list-content6;
}

#order-list-content7 {
  grid-area: order-list-content7;
}

#order-list-content8 {
  grid-area: order-list-content8;
  border-list-bottom: 1px solid black;
}

#order-list-content9 {
  grid-area: order-list-content9;
  border-list-bottom: 1px solid black;
}

#order-list-content10 {
  grid-area: order-list-content10;
}

#order-list-content11 {
  grid-area: order-list-content11;
}

@media only screen and (max-width: 600px) {
  .order-list-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr auto;
    grid-template-areas:
      "order-list-content0 order-list-content0"
      "order-list-content1 order-list-content1"
      "order-list-content2 order-list-content2"
      "order-list-content3 order-list-content3"
      "order-list-content4 order-list-content5"
      "order-list-content6 order-list-content7"
      "order-list-content8 order-list-content9"
      "order-list-content10 order-list-content11";
  }
}

/* ADD MEDIA QUERY FOR MOBILE LATER */

* {
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --black: rgb(14, 13, 13);
  --active-color: #f1f1f1;
  --border-radius: 20px;
}

.tab-links {
  padding: 0;
  margin: 0 auto 20px;
  list-style: none;
  max-width: 100%;
  display: -webkit-flex;
  display: flex;
}

.tab {
  position: relative;
}

.tab a {
  text-decoration: none;
  color: rgb(14, 13, 13);
  color: var(--black);
}

.tab::before {
  content: "";
  left: 25px;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  position: absolute;
  border-radius: 9px;
  transition: background 0.5s ease;
  border-bottom: 1px solid black;
}

.tab.icon-tab::before {
  left: 0 !important;
}

/* .tab svg {
    min-width: 30px;
    fill: var(--black);
    transition: fill 0.5s ease;
    font-size: 500%;
} */

.tab.active::before {
  background: #f1f1f1;
  background: var(--active-color);
}

.tab span {
  font-weight: 700;
  margin-left: 20px;
  transition: color 0.5s ease;
}

.tab.active span {
  color: #f1f1f1;
  color: var(--active-color);
}

.tab.active svg {
  fill: #f1f1f1;
  fill: var(--active-color);
}

.tab a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 500;
  overflow: hidden;
  position: relative;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

.description {
  font-size: 90%;
}

/* UPDATE TABS */
.update-tab-component {
  max-width: 100%;
  margin: auto;
  background: #fff;
  background: var(--white);
  padding: 10px;
  border-radius: 9px;
  box-shadow: 0 30px 30px rgba(0, 0, 0, 0.1);
}

.update-tab-links {
  padding: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.update-tab {
  position: relative;
}

.update-tab a {
  text-decoration: none;
  color: rgb(14, 13, 13);
  color: var(--black);
}

.update-tab::before {
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0.2;
  position: absolute;
  border-radius: 9px;
  background: none;
  transition: background 0.5s ease;
  border-bottom: 1px solid black;
}

.update-tab svg {
  min-width: 30px;
  fill: rgb(14, 13, 13);
  fill: var(--black);
  transition: fill 0.5s ease;
  font-size: 500%;
}

.update-tab.active::before {
  background: #f1f1f1;
  background: var(--active-color);
}

.update-tab span {
  font-weight: 700;
  margin-left: 20px;
  transition: color 0.5s ease;
}

.update-tab.active span {
  color: #f1f1f1;
  color: var(--active-color);
}

.update-tab.active svg {
  fill: #f1f1f1;
  fill: var(--active-color);
}

.update-tab a {
  padding: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 500;
  overflow: hidden;
  position: relative;
}

.update-tab-content {
  display: none;
}

.update-tab-content.active {
  display: block;
}

.description {
  font-size: 90%;
}

.filter-button{
  position: fixed;
  bottom: 1%;
  left: calc(50% - 50px);    
  border-radius: 5%;
  width: 100px;
  height: 30px;
  background-color: white;
  color: grey;
  border: 1px solid white;
  box-shadow: 0px 8px 15px rgb(0, 0, 0, 0.1);
  z-index: 1;
}

.filter-button:hover{
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  color:orange;
}

.search-filter-container{
  width: 97%;
  max-width: 800px;
  height: 80%;
  background-color: white;
  border-radius: 1%;
  z-index: 3;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.search-filter-container::-webkit-scrollbar{
  display:none;
}

.filter-field{
  background-color:rgba(206, 206, 206, 0.1);
  margin: 6px 2%;
}

