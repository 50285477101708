.filter-button{
  position: fixed;
  bottom: 1%;
  left: calc(50% - 50px);    
  border-radius: 5%;
  width: 100px;
  height: 30px;
  background-color: white;
  color: grey;
  border: 1px solid white;
  box-shadow: 0px 8px 15px rgb(0, 0, 0, 0.1);
  z-index: 1;
}

.filter-button:hover{
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
  color:orange;
}

.search-filter-container{
  width: 97%;
  max-width: 800px;
  height: 80%;
  background-color: white;
  border-radius: 1%;
  z-index: 3;
  box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
  display: flex;
  flex-direction: column;
}

.search-filter-container::-webkit-scrollbar{
  display:none;
}

.filter-field{
  background-color:rgba(206, 206, 206, 0.1);
  margin: 6px 2%;
}
