/* shipping item grid */

.orange-title{
  color:orange;
  font-family: "Montserrat";
  font-weight: 600;
}

.shipping-item-container {
    display: grid;
    grid-template-columns: 0.4fr 0.4fr 0.4r ;
    grid-template-rows: 0.7fr 0.7fr 0.5fr 0.7fr;
    grid-template-areas: 
    "shipping-item0 shipping-item0 shipping-item0"
    "shipping-item1 shipping-item2 shipping-item3"
    "shipping-item4 shipping-item5 shipping-item6"
    "shipping-item4 shipping-item7 shipping-item7"
    "shipping-item4 shipping-item8 shipping-item8"
    "shipping-item9 shipping-item9 shipping-item10"
    "shipping-item11 shipping-item11 shipping-item12"
    "shipping-item13 shipping-item13 shipping-item13"
    "shipping-item14 shipping-item14 shipping-item14";
    grid-gap: 2rem;
    border-radius: 25px;
  
    justify-content: center;
    background-color: transparent;

  }

  .shipping-item-title{
    color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 130%;

  }

  .shipping-item-subtitle {
    color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 550;
}

  #shipping-item0 {
    border-radius: 25px;
    grid-area: shipping-item0;
    color:orange;
    font-family: "Montserrat";
    font-weight: 600;
  }

  #shipping-item1 {
    border-radius: 25px;
    grid-area: shipping-item1;
    
  }
  
  #shipping-item2 {
    border-radius: 25px;
    grid-area: shipping-item2; 
  }

  #shipping-item3{
    grid-area: shipping-item3;
    border-radius: 25px;
  }
  
  #shipping-item4 {
    border-radius: 25px;
    grid-area: shipping-item4;
  }

  #shipping-item5 {
    grid-area: shipping-item5;
    border-radius: 25px;
  }

  #shipping-item6 {
    grid-area: shipping-item6;
    border-radius: 25px;
  }

  #shipping-item7 {
    grid-area: shipping-item7;
    border-radius: 25px;
  }

  #shipping-item8 {
    grid-area: shipping-item8;
    border-radius: 25px;
    text-align: center;
  }

  #shipping-item9 {
    grid-area: shipping-item9;
    border-radius: 25px;
  }

  #shipping-item10 {
    grid-area: shipping-item10;
    border-radius: 25px;
    text-align: center;
  }

  #shipping-item11 {
    grid-area: shipping-item11;
    border-radius: 25px;
  }

  #shipping-item12 {
    grid-area: shipping-item12;
    border-radius: 25px;
    text-align: center;
  }

  #shipping-item13 {
    grid-area: shipping-item14;
    border-radius: 25px;
    text-align: center;
  }

  #shipping-item14 {
    grid-area: shipping-item14;
  }


  @media only screen and (max-width: 600px) {
    .shipping-item-container {
      grid-template-columns: 0.8fr;
      justify-content: center;
      text-align: center;
      grid-template-rows: 0.2fr auto ;
      grid-template-areas:
        "shipping-item0"
        "shipping-item1"
        "shipping-item2"
        "shipping-item3"
        "shipping-item4"
        "shipping-item5"
        "shipping-item6"
        "shipping-item7"
        "shipping-item8"
        "shipping-item9"
        "shipping-item10"
        "shipping-item11"
        "shipping-item12"
        "shipping-item13"
        "shipping-item14"
    }
  } 


   /* show items grid */

   .show-item-container {
    display: grid;
    grid-template-columns: 0.7fr;
    grid-template-rows: 0.7fr 0.7fr;
    grid-template-areas: 
    "show-item0 show-item0 show-item0"
    "show-item1 show-item1 show-item1";
    grid-gap: 2rem;
    border-radius: 25px;
    height:60vh;
    justify-content: center;
    background-color: transparent;

  }

  #show-item0 {
    border-radius: 25px;
    grid-area: show-item0;
    font-family: "Montserrat";
    font-weight: 600;
  }

  #show-item1 {
    border-radius: 25px;
    grid-area: show-item1;
    text-align: center;
    
  }
  


  /* freight class grid 8*/
  .freight-class-container {
    display: grid;
    grid-template-columns: 0.2fr 0.6fr ;
    grid-template-rows: 0.2fr 0.2fr;
    grid-template-areas: 
    "freight-content1 freight-content2";
    justify-content: center;
    background-color: transparent;
  }

.freight-item-class{
  color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 250%;
}
.freight-item-subtitle{
  color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 190%;
}

.freight-item-body{
  color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 150%;
}

#freight-content0 {
  color:orange;
  font-family: "Montserrat";
  text-align: center;
  font-weight: 600;
}
#freight-content1 {
  grid-area: freight-content1;
  text-align: center;
  background-color:white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1)

}
#freight-content2 {
  grid-area: freight-content2;
  background-color: white;
  text-align: center;
  justify-content: center;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1)
}
#freight-content3 {
  grid-area: freight-content3
}
#freight-content4 {
  grid-area: freight-content4
}

.freight-button-center{
  text-align: center;
}

#freight-button-back {
  background-color: white;
}

#freight-button-forward {
 background-color: rgb(40, 116, 40);
}

.freightFlex {
  flex: 1;
  flex-direction: row;
  color: rgb(40, 116, 40);
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 250%;
}


@media only screen and (max-width: 600px){
  .freight-class-container {
    grid-template-columns: 0.6fr;
    grid-template-rows: 0.2fr 0.2fr 0.4fr;
    grid-template-areas:
    "freight-content1 freight-content2"
  }
}

/*item-container*/

.item-container {
  display : grid;
  grid-template-columns: 1fr 0.2fr;
  grid-template-rows: 0.5fr 0.2fr;
  grid-template-areas: 
  "item-content1 item-content2";
  justify-content: center;
  background-color: transparent;
  flex-direction: row;
}

.item-content-title{
  color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 190%;
}
.item-content-subtitle{
  color: rgb(40, 116, 40);
    font-family: "Montserrat";
    font-weight: 300;
    font-size: 150%;
}

#item-content1 {
  grid-area: item-content1;
  font-family: "Montserrat";
  background-color:white;
  text-align: center;
  flex-direction: column;
  font-weight: 600;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1)
}

#item-content2 {
  grid-area: item-content2;
  text-align: center;
  font-family: "Montserrat";
  background-color:white;
  justify-content: center;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1)

}


/*quote-summary container*/
.summary-container {
  display:grid;
  grid-template-columns: 500px auto ;
  grid-template-rows: 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr;
  grid-template-areas:
  "summary-content0 summary-content0 "
  "summary-content1 summary-content2"
  "summary-content3 summary-content4"
  "summary-content5 summary-content6"
  "summary-content7 summary-content8"
  "summary-content9 summary-content10";
  grid-gap: 0.5rem;
  border-radius: 25px;
  justify-content: center;
}

@media only screen and (max-width: 600px){
  .summary-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr 0.2fr 0.4fr;
    grid-template-areas:
    "summary-content0 summary-content0 "
  "summary-content1 summary-content2"
  "summary-content3 summary-content4"
  "summary-content5 summary-content6"
  "summary-content7 summary-content8"
  "summary-content9 summary-content10";
  }
}

#summary-content0 {
  border-radius: 25px;
  
}

#summary-content1 {
  border-radius: 25px;
  
}

#summary-content2 {
  border-radius: 25px;

  
}

#summary-content3{
  border-radius: 25px;
}

#summary-content4 {
  border-radius: 25px;
 
}

#summary-content5 {
  border-radius: 25px;
}

#summary-content6 {
  border-radius: 25px;
}

.summary-header {
  font-size: 16pt;
  color: rgb(141, 173, 155);
  font-family: "Montserrat";
}

.summary-body-text{
  font-size: 13pt;
  color: seagreen;
  font-family: "Montserrat";
}

/* review-and-confirm container*/

.review-container {
  display:grid;
  grid-template-columns: 500px auto ;
  grid-template-rows: 0.7fr auto;
  grid-template-areas:
  "review-content0 review-content1"
  "review-content2 review-content3"
  "review-content4 review-content5"
  "review-content6 review-content7"
  "review-content8 review-content9"
  "review-content10 review-content11"
  "review-content12 review-content13"
  "review-content14 review-content15"
  "review-content16 review-content17"
  "review-content18 review-content19"
  "review-content20 review-content21"
  "review-content22 review-content22"
  "review-content23 review-content24"
  "review-content25 review-content25"
  "review-content26 review-content26"
  "review-content27 review-content27"
  "review-content28 review-content28";
  grid-gap: 0.5rem;
  border-radius: 25px;
  justify-content: center;
}

#review-content0 {
  grid-area: review-content0;
 
}

#review-content1 {
  grid-area: review-content1;
}

#review-content2 {
  grid-area: review-content2;
}

#review-content3 {
  grid-area: review-content3;
}

#review-content3 {
  grid-area: review-content3;
}

#review-content4 {
  grid-area: review-content4;
}

#review-content5 {
  grid-area: review-content5;
}

#review-content6 {
  grid-area: review-content6;
}

#review-content7 {
  grid-area: review-content7;
}

#review-content8 {
  grid-area: review-content8;
}

#review-content9 {
  grid-area: review-content9;
}

#review-content10 {
  grid-area: review-content10;
}

#review-content11 {
  grid-area: review-content11;
}

#review-content12 {
  grid-area: review-content12;
}

#review-content13 {
  grid-area: review-content13;
}

#review-content14 {
  grid-area: review-content14;
}

#review-content15 {
  grid-area: review-content15;
}

#review-content16 {
  grid-area: review-content16;
}

#review-content17 {
  grid-area: review-content17;
}

#review-content18 {
  grid-area: review-content18;
}

#review-content19 {
  grid-area: review-content19;
}

#review-content20 {
  grid-area: review-content20;
}

#review-content21 {
  grid-area: review-content21;
}

#review-content22 {
  grid-area: review-content22;
  border-top: 1px solid black;
  
}

#review-content23 {
  grid-area: review-content23;
  font-weight: 600;
}

#review-content24 {
  grid-area: review-content24;
  font-weight: 600;
}

#review-content25 {
  grid-area: review-content25;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  grid-auto-flow: column;
}

#review-content26 {
  grid-area: review-content26;
}

#review-content27 {
  grid-area: review-content27;
}

#review-content28 {
  grid-area: review-content28;
}



@media only screen and (max-width: 600px){
  .review-container {
    grid-template-columns: 250px auto;
    grid-template-rows: 0.2fr 0.2fr 0.4fr;
    grid-template-areas:
    "review-content0 review-content1"
    "review-content2 review-content3"
    "review-content4 review-content5"
    "review-content6 review-content7"
    "review-content8 review-content9"
    "review-content10 review-content10"
    "review-content12 review-content13"
    "review-content14 review-content15"
    "review-content16 review-content17"
    "review-content18 review-content19"
    "review-content20 review-content21"
    "review-content22 review-content22"
    "review-content23 review-content24"
    "review-content25 review-content25"
    "review-content26 review-content26"
    "review-content27 review-content27"
    "review-content28 review-content28";
  }
}

.review-credit-container{
  display:grid;
  grid-template-columns: 0.3fr 0.6fr ;
  grid-template-rows: 0.7fr 0.7fr ;
  grid-template-areas:
  "review-credit-content0 review-credit-content1";
  grid-gap: 0.5rem;
  border-radius: 25px;
  justify-content: center;
  text-align: center;
}

.review-credit-content0{
  grid-area: review-credit-content0;
}

.review-credit-content1{
  grid-area: review-credit-content1;
}
